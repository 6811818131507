import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenProductKolyaski() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const type = filtersAll.filter((item) => item.type === 53)
  const ageChild = filtersAll.filter((item) => item.type === 'ageChild')
  const brandCarSeatsStrollers = filtersAll.filter(
    (item) => item.type === 'brandCarSeatsStrollers',
  )
  const strollersBlChild = filtersAll.filter(
    (item) => item.type === 'strollersBlChild',
  )

  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'detskie'}
      subCategoryRoute={'kolyaski'}
      category={category}
      categoryId={1}
      columnsId={4}
      subCategoryId={53}
      title={'Игрушки и игры'}
      subTitle={'Детские товары'}
      isAksessuary={true}
      aksessuary={type}
      isAgeChild={true}
      ageChild={ageChild}
      isBrands={true}
      brands={brandCarSeatsStrollers}
      isStrollersBlChild={true}
      strollersBlChild={strollersBlChild}
    />
  )
}
export default ChildrenProductKolyaski
